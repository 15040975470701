import { StorageServices } from '@/services/StorageServices';
import { createStore } from 'vuex'
import * as OM from '@/Model';
import { NotificationClient } from '@/services/Services';

let vuestore = {
    state: {
        user: <OM.User>null,
        company: <OM.Company>null,
        formatLocaleDate: null,
        // restrictedCategorie: <string[]>[],
        appCode: '',
        consts: {
            localizedValues: <OM.LocalizedEntity[]>[]
        },
        showSpinner: 0,
        isMobile: false,
        newNotificationCount: 0,
        appTitle: 'Welfood',
        beforeNavCbs: [],
        afterNavCbs: [],
        systemCopy: copyToClipboard,
        notification: null,
        notificationCallback: (payload: any) => {
            console.log("notification received", payload);
        },
        prenotazioneVm: <OM.MakePrenotazioneVm>null,
        notificheCount: 0,
        getNotificheCount: () => {
            NotificationClient.getUnreadCount()
            .then(x => {
                store.state.notificheCount = x;
            })
        }
    }
}

vuestore.state.prenotazioneVm = StorageServices.getMakePrenotazioneVm();

let subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : '';

if(subdomain != 'app'){
    vuestore.state.appCode = subdomain;
}
if(!vuestore.state.appCode){
    vuestore.state.appCode = "wftest";
}

vuestore.state.isMobile = window.innerWidth <= 768;
window.addEventListener('resize', () => {
    store.state.isMobile = window.innerWidth <= 768;
})

let store = createStore(vuestore);
export default store


function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    window.toastr.success("Copiato!");
}