
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { ModalServices } from '@/services/ModalServices';
import { StorageServices } from './services/StorageServices';
import { CommonServices } from './services/CommonServices';
import { LoginServices } from './services/LoginServices';
import { CompanyClient, LocalizedClient, LoginClient } from './services/Services';
import router from './router';
import store from './store';
import { LocalizationServices } from './services/LocalizationServices';

@Options({})
export default class App extends Vue {

    loading: boolean = true;
    
    mounted(){
        Promise.all([
            CompanyClient.getByCompanyCode(store.state.appCode),
            LocalizedClient.getByCompany(store.state.appCode),
        ])
        .then(xs => {
            store.state.company = xs[0];
            store.state.company.companyCode = xs[0].companyCode.toLocaleLowerCase();
            store.state.formatLocaleDate = xs[0].dateFormatLocale;
            store.state.consts.localizedValues = xs[1];
            window.document.title = store.state.company.name;

            if(this.$route.name == 'change_password' || this.$route.name == 'confirm_email'){
                this.loading = false;
                return;
            }

            let token = CommonServices.getAuthToken();
            if(token){
                LoginClient.refreshLogin()
                .then(x => {
                    LoginServices.loginCallback(x)
                    .then(x => {
                        this.loading = false;
                    });
                })
                .catch(err => {
                    this.loading = false;
                })
            } else {
                this.loading = false;
            }
        })

        ModalServices.setOpModal(this.$opModal);
    }
}
